import { Component } from "react";
import { merge } from "./merge/merge";

export function updateState<T>(
    component: Component<any, T>,
    update: ((prev: T) => MergePartial<T>) | MergePartial<T>,
    callback?: () => void
) {
    component.setState(
        (prev) =>
            merge(prev, update instanceof Function ? update(prev) : update),
        callback
    );
}

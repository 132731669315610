import React, { Component } from "react";

interface LoadingProps {
    src?: string;
    className?: string;
    alt?: string;
}

export class Loading extends Component<LoadingProps> {
    static defaultProps: LoadingProps = {
        src: "/img/loader.gif",
        className: "center-block",
        alt: "Loading...",
    }

    render() {
        return (
            <img {...this.props} />
        );
    }
}

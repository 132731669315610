import React, { RefObject, forwardRef, ComponentProps } from "react";

export interface ConditionalProps {
    condition: boolean;
}

export const withConditional: Hoc<ConditionalProps> = (WrapIt) => {
    type WithConditionalProps = ComponentProps<typeof WrapIt> & ConditionalProps;

    return forwardRef((props: WithConditionalProps, ref: RefObject<any>) => {
        let { condition, ...restProps } = props;
        if (!condition) {
            return null;
        }
        return <WrapIt {...(restProps as any)} ref={ref} />;
    });
};

import React, { PropsWithChildren, ComponentProps } from "react";

type ComposeProps<T extends AllComponentTypes<any>> = PropsWithChildren<{
    Tag: T;
}> &
    ForwardedRef &
    Omit<ComponentProps<T>, "Tag" | "forwardedRef">;

export function Compose<T extends AllComponentTypes<P>, P = any>(
    props: ComposeProps<T>
) {
    let { Tag, forwardedRef, children, ...restProps } = props;
    let adjustedProps: any = {
        ...restProps,
        ref: forwardedRef,
    };
    return <Tag {...(adjustedProps as any)}>{children}</Tag>;
}

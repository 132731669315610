import React from "react";

import styles from "./ErrorBubble.m.css";
import { Compose } from "./Compose";
import { withConditional } from "./withConditional";
import { classNames } from "../../modules/modules";

interface ErrorBubbleProps {
    errorText?: string,
    isBox?: boolean,
}

export class ErrorBubble extends React.Component<ErrorBubbleProps> {
    render() {
        var {isBox, errorText} = this.props;
        return (
            <Compose
                Tag={withConditional("div")}
                condition={!!errorText}
                {...classNames(styles.errorBox, !isBox ? styles.errorBubble : "")}
            >
                <div className={styles.errorBubbleMsg}>{errorText}</div>
            </Compose>
        );
    }
}

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { GleanerReactComponent } from "./GleanerReactComponent";
import { Loading } from "./components/shared/common";

const loginComponent: GleanerReactComponent = {
    name: "LoginForm",
    create: React.lazy(() => import(/* webpackChunkName: "login-form" */ "./components/authentication/LoginForm").then((module) => ({default: module.LoginFormFactory})))
};

const dynamicLink: GleanerReactComponent = {
    name: "DynamicLink",
    create: React.lazy(() => import(/* webpackChunkName: "dynamic-link" */ "./components/shared/DynamicLink").then((module) => ({default: module.DynamicLinkFactory})))
};

// const searchPage: GleanerReactComponent = {
//     name: "AgentFormsSearch",
//     create: React.lazy(() => import(/* webpackChunkName: "search-page" */ "./components/agentForms/AgentFormsSearch").then((module) => ({default: module.AgentFormsSearchFactory})))
// }

const components: GleanerReactComponent[] = [loginComponent, dynamicLink];

function renderComponent(
    component: GleanerReactComponent,
    container: HTMLElement
): void {
    let { glReact, ...data } = $(container).data();
    let comp = <Suspense fallback={<Loading />}><component.create {...data} /></Suspense>
    ReactDOM.render(comp, container);
}

export function renderComponentByName(
    componentName: string,
    container: HTMLElement,
    extraProps?: { [prop: string]: any }
): void {
    let component = components.find((c) => c.name === componentName);
    if (extraProps) {
        $(container).data(extraProps);
    }
    renderComponent(component, container);
}

export function renderAllComponents(): void {
    components.forEach((component) => {
        $(`div[data-gl-react="${component.name}"]`).each((_, container) => {
            renderComponent(component, container);
        });
    });
}

$(() => {
    renderAllComponents();
});

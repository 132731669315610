import { mergeArray } from "./mergeArray";

export function merge<T>(o1: T, o2: MergePartial<T>): T {
    if (typeof o1 !== "object" || o1 === null) {
        if (Array.isArray(o2)) {
            return mergeArray([] as T & any[], o2 as MergePartial<T & any[]>);
        }
        return o2 as T;
    }
    if (Array.isArray(o1)) {
        return mergeArray(o1 as T & any[], o2 as MergePartial<T & any[]>);
    }

    let result: T = { ...o1 };
    for (let p in o2) {
        if (o2.hasOwnProperty(p)) {
            let prop = p as string;
            result[prop] = o2[prop] == null ? o2[prop] : merge(result[prop], o2[prop]);
        }
    }
    return result;
}

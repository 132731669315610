import { merge } from "./merge";

export function mergeArray<T, U extends Array<T>>(
    o1: U,
    o2: MergePartial<U>
): U {
    let result: U = [...o1] as U;
    for (let item of o2) {
        switch (item.operation) {
            case ArrayMergeOperation.Delete:
                result.splice(item.key, 1);
                break;
            case ArrayMergeOperation.Insert:
                result.splice(item.key, 0, item.value);
                break;
            case ArrayMergeOperation.Push:
                result.push(item.value);
                break;
            case ArrayMergeOperation.ReplaceArray:
                result.splice(0).concat(item.value);
                break;
            case ArrayMergeOperation.Update:
                result[item.key] = merge(result[item.key], item.value);
                break;
        }
    }
    return result;
}
